import { Html, useProgress } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { HeadModel } from "./model";
import { Suspense } from "react";

export const Head = () => {
  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        overflowY: "hidden", // hide vertical
      }}
    >
      <div
        style={{
          position: "relative",
          background: "transparent",
          height: "100%",
          zIndex: "100",
        }}
      >
        <Canvas>
          <Suspense fallback={<Loader />}>
            <ambientLight intensity={1.5} />
            <HeadModel />
          </Suspense>
        </Canvas>
        <div
          style={{
            position: "absolute",
            bottom: "10rem",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Title />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#eed6ff",
          position: "absolute",
          zIndex: "-100",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
        }}
      ></div>
    </div>
  );
};
function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
}

function Title() {
  return (
    <div
      style={{
        marginBottom: "10rem",
        textAlign: "center",
      }}
    >
      <h1
        style={{
          color: "#cf0eb2",
          fontFamily: "monospace",
        }}
      >
        Donold Tromp
      </h1>
      <h4
        style={{
          color: "#cf0eb2",
          fontFamily: "monospace",
        }}
      >
        mek emerica gret egen
      </h4>
      <h4
        style={{
          color: "#cf0eb2",
          fontFamily: "monospace",
        }}
      >
        <a
          href="https://birdeye.so/token/DUVa4yXCYkbunujy6EUpQ8jsPfb2uwNudc4UHJFJbAtQ?chain=solana"
          style={{ color: "#cf0eb2", fontFamily: "monospace" }}
        >
          ca: DUVa4yXCYkbunujy6EUpQ8jsPfb2uwNudc4UHJFJbAtQ
        </a>
      </h4>
      <h4
        style={{
          color: "#cf0eb2",
          fontFamily: "monospace",
        }}
      >
        <a
          href="https://t.me/+CjipapQPWak5YTFh"
          style={{ color: "#cf0eb2", fontFamily: "monospace" }}
        >
          Telegram
        </a>
        <span style={{ color: "#cf0eb2", fontFamily: "monospace", margin: "0 10px" }}>•</span>
        <a
          href="https://twitter.com/tromponsol"
          style={{ color: "#cf0eb2", fontFamily: "monospace" }}
        >
          Twitter
        </a>
      </h4>
    </div>
  );
}
