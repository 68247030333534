import { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";

// import { Mesh } from "@react-three/drei";
export function HeadModel(props: any) {
  const texture = useLoader(TextureLoader, "trompr.jpg");
  const meshRef = useRef();
  let zPosition = 0;
  useFrame(() => {
    if (meshRef.current) {
      zPosition += 0.01;
      if (zPosition > 6) {
        zPosition = 0;
      }
      // @ts-ignore
      meshRef.current.position.z = zPosition;
    }
  });
  return (
    // @ts-ignore
    <mesh ref={meshRef}>
      <planeBufferGeometry attach="geometry" />
      <meshBasicMaterial attach="material" map={texture} />
    </mesh>
  );
}
